@import "../../styles/core.scss";

.section {
    .sectionBox {
        position: relative;

        &.background {
            color: #fff;
            background-color: #0008;
        }


        .textCol {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 128px 0px;

            &>* {
                text-align: center;
                font-size: 16px;

                @media (min-width: 768px) {
                    font-size: 28px;
                }
            }

            p {
                margin: 16px;
            }
        }
    }
}