.navigationBox {
    transition: 0.3s;
    opacity: 0;
    
    &.open {
        opacity: 100;
    }

    top: 0;
    bottom:0;
    position:fixed;
    overflow-y:scroll;
    overflow-x:hidden;
    
    z-index: 10;
    
    min-height: 100vh;
    width: 100vw;
    font-size: 32px;
    background-color: #232021;

    .titleBox {
        height: 20%;

        .close {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 10vh;
            max-height: 10vh;
    
            svg {
                cursor: pointer;
            }
        }

        .title {
            text-align: center;
            font-size: 48px;
            // margin-bottom: 64px;
        }
    }

    .links {
        height: 80%;
        
        div {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .element {
            display: flex;
            justify-content: center;
            font-size: 32px;
            text-align: center;

            p {
                cursor: pointer;
            }

        }
    }
}