@import "../../styles/core.scss";

.section {
    .sectionBox {
        position: relative;

        &.background {
            color: #fff;
            background-color: #0008;
        }

        font-size: 24px;

        .contactRow {
            padding-top: 96px;
            height: 100%;
            display: flex;
            align-items: flex-start;

            .socialsCol {
                .socialRow {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    
                    gap: 8px;
                    margin: 8px 0px;

                    @media (min-width: 768px) {
                        gap: 16px;
                    }

                    .icon {
                        
                        a, div {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 100px;
                            color: #fff;
                            background-color: #000;
                            font-size: 16px;
                            width: 24px;
                            height: 24px;
                            
                            @media (min-width: 768px) {
                                width: 32px;
                                height: 32px;
                                font-size: 20px;
                            }

                            @media (min-width: 1096px) {
                                width: 40px;
                                height: 40px;
                                font-size: 24px;
                            }

                        }


                    }

                    .link {
                        display: flex;
                        align-items: center;

                        a, div {
                            color: inherit;
                            font-size: 20px;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;

                            @media (min-width: 768px) {
                                font-size: 24px;
                            }

                            @media (min-width: 1096px) {
                                font-size: 28px;
                            }
                        }
                    }

                }
            }

            .formCol {

                .group {

                    &>*:not(:first-child) {
                        margin: 16px 0;
                    }

                    &>* {
                        border-radius: 0px;
                        border: 2px #000 solid;
                        
                        font-size: 16px;
                        
                        @media (min-width: 768px) {
                            font-size: 22px;
                        }
                    }

                    &.error {
                        &>* {
                            border: 2px #e72626 solid;
                        }
                    }

                    .btn {
                        color: #000;
                        background-color: #fff;
                        width: 100%;
                        transition: 0.3s;

                        &:hover {
                            background-color: #0001;
                        }
                    }
                }
            }
        }
    }

    .msg {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font-size: 16px;
        
        &.errMsg {
            color: #e72626;
        }
    }
}