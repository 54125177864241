@import "../../styles/core.scss";

.section {
    .sectionBox {
        position: relative;
        min-height: 100%;
        color: #fff;

        &.background {
            background-color: #0008;
        }

        .galleryRow {
            z-index: 0;
            height: 100%;
            .galleryCol {
                padding: 0px;
            }
        }
    }
}