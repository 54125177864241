.navRow {
    background-color: #232021;
    min-width: 100%;
    min-height: 100vh;
    height: 100%;
    color: #fff;
    font-size: 32px;

    @media (min-width: 768px) {
        font-size: 48px;
    }
    
    .icon {
        height: 10vh;
        display: flex;
        justify-content: center;
        align-items: center;
    
        svg {
            cursor: pointer;
        }
    }

    .title {
        pointer-events: none;

        div {
            width: 100%;
            display: flex;
            align-items: center;
            writing-mode: vertical-lr;
            white-space: nowrap;
            
            // position: sticky;
            // top: 16px;
        }
    }
    
    .social {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: #fff;
        border-radius: 100px;
        width: 42px;
        height: 42px;
        color: #000;
        position: fixed;
        right: 20px;
        z-index: 11;
    }
}