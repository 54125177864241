@import "../../styles/core.scss";

.section {
    min-height: 100vh;
    background-color: white;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .sectionBox {
        position: relative;

        &.background {
            color: #fff;
            background-color: #0008;
        }

        .videoRow {
            height: 100%;
            .video {
                padding: 0px;
                height: 100%;
                iframe {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}