.footerRow {
    background-color: #232021;
    color: #fff;
    border-top: solid 2px #514E4F;
    padding: 6px 8px;

    @media (min-width: 768px) {
        padding: 16px 24px;
    }

    .copyrights {
        display: flex;
        align-items: center;
        gap: 4px;
        
        justify-content: flex-start;
    }

    .workers {
        display: flex;
        align-items: center;
        gap: 4px;

        justify-content: flex-start;

        @media (min-width: 768px) {
            justify-content: flex-end;
        }
    }
}