@import "./styles/core.scss";

@font-face {
    font-family: "Poiret One";
    src: url(../static/PoiretOne-Regular.woff2);
}

.body {
    background-color: #232021;
    font-family: 'Poiret One';
    min-height: 100vh;
    margin: 0px 0px;
    min-width: 100%;
    max-width: 100vw;

    .whole {
        min-height: 100vh;
        max-width: 100vw;
    }
}
