.title {
    position: absolute;
    text-align: end;
    padding: 12px;
    margin: -12px;

    display: flex;
    justify-content: flex-end;

    font-size: 32px;

    @media (min-width: 768px) {
        font-size: 48px;
        
    }
    width: 100%;
}

.section {
    min-height: 100vh;
    background-color: black;;

    transition: all 1s;
    
    background-repeat: no-repeat;
    background-size: cover;

    // @media (min-width: 1224px) {
        // background-size: 110%;   
    // }
    background-position: top;
}

.sectionBox {
    background-color: white;
}